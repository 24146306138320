import { Component, OnDestroy, Inject, ViewChild, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from './../../_nav';
import { AuthenticationModal } from '../../components/authentication-modal.module';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  public element: HTMLElement;

  public authenticateModal;
  public authKey: string;
  public isLoggedIn: boolean;

  private changes: MutationObserver;

  @ViewChild('childModal') childModal: AuthenticationModal;
  constructor(
    public authService: AuthenticationService,
    @Inject(DOCUMENT) _document?: any,
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  ngOnInit(): void {
    this.isLoggedInUpdate();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logoff();
  }

  public isLoggedInUpdate() {
    return this.authService.getIsAuthenticatedObservable().subscribe(
      data => {
        console.log('Updated isLoggedIn to ', data);
        this.isLoggedIn = data;
      }
    );
  }

}
