import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AuthenticationModal } from './components/authentication-modal.module';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

import {
  AuthModule,
  ConfigResult,
  OidcConfigService,
  OidcSecurityService,
  OpenIdConfiguration
} from 'angular-auth-oidc-client';

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.load_using_custom_stsServer(
    'https://sso.maier-eu.at/auth/realms/maier-eu.at/.well-known/openid-configuration'
  );
}

// import the logging framework for easier logging
import {
  LoggerModule,
  NgxLoggerLevel,
  NGXLogger
} from 'ngx-logger';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/authentication/interceptor.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    ModalModule,
    FormsModule,
    AuthModule.forRoot(),
    // set logging options, if production set loglevel to info and disable console log
    // TODO: send logs somewhere with option 'serverLoggingUrl' by using POST requests 
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,
      disableConsoleLogging: environment.production ? true : false
    })
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    AuthenticationModal
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private oidcConfigService: OidcConfigService,
    private logger: NGXLogger
  ) {

    this.logger.info('[app.module.ts] Starting Application with Logging enabled');
    this.logger.info(`[app.module.ts] Application is started in production mode: {environment.production}`);

    this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {

      // set redirect url depending on production setting
      let redirectUrl = environment.production ? "https://www.maier-eu.at/callback" : "https://dev.maier-eu.at/callback";
      let logoutUrl = environment.production ? "https://www.maier-eu.at/logout" : "https://dev.maier-eu.at/logout";
      let silentRenewUrl = environment.production ? "https://www.maier-eu.at/assets/silent.html'" : "https://dev.maier-eu.at/assets/silent.html'";

      const config: OpenIdConfiguration = {
        stsServer: configResult.customConfig.stsServer,
        redirect_url: redirectUrl,
        post_logout_redirect_uri: logoutUrl,
        client_id: 'maiereuat_angular_frontend',
        scope: 'openid',
        response_type: 'code',
        silent_renew: true,
        silent_renew_url: silentRenewUrl,
        log_console_debug_active: true,
        max_id_token_iat_offset_allowed_in_seconds: 60
        // all other properties you want to set
      };

      this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);

      /*
      const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
      openIDImplicitFlowConfiguration.stsServer = "https://sso.maier-eu.at";
      openIDImplicitFlowConfiguration.redirect_url = "https://dev.maier-eu.at/callback";
      openIDImplicitFlowConfiguration.post_logout_redirect_uri = "https://dev.maier-eu.at/logout";
      // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer
      // identified by the iss (issuer) Claim as an audience.
      // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience,
      // or if it contains additional audiences not trusted by the Client.
      openIDImplicitFlowConfiguration.client_id = "maiereuat_angular_frontend";
      openIDImplicitFlowConfiguration.response_type = "code";
      openIDImplicitFlowConfiguration.scope = "openid";
      openIDImplicitFlowConfiguration.start_checksession = true;
      openIDImplicitFlowConfiguration.log_console_warning_active = true;
      openIDImplicitFlowConfiguration.log_console_debug_active = false;
      // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
      // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
      openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 10;
      openIDImplicitFlowConfiguration.silent_renew = true;
      openIDImplicitFlowConfiguration.silent_redirect_url = 'https://dev.maier-eu.at/callback';
      openIDImplicitFlowConfiguration.silent_renew_url = 'assets/silent.html';

      const authWellKnownEndpoints = new AuthWellKnownEndpoints();
      authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);

      console.log(openIDImplicitFlowConfiguration);
      this.oidcSecurityService.setupModule(
        openIDImplicitFlowConfiguration,
        authWellKnownEndpoints
      );
      */

    });

    console.log('APP STARTING');
  }


}
